import Image from 'components/common/Image';
import { BiographyUserProps } from 'interfaces/cms/content';
import moment from 'moment';
import QuestionMark from '@public/icons/question_mark.svg';
import ProfileCard from './ProfileCard';
import { Tooltip } from 'react-tooltip';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { UserProfileIcon } from '@alphafounders/icons';

export enum UserType {
  Author,
  Editor,
  Reviewer,
}

export interface UserBioProps {
  type: UserType;
  user: BiographyUserProps;
  date: Date;
}

function ProfileLink({ user }: { user: BiographyUserProps }) {
  return (
    <>
      <Link href={user.profileUrl || '#'} className="text-blue-400">
        {user.firstname} {user.lastname}
      </Link>
      <QuestionMark
        className="w-3 h-3"
        data-tooltip-id={`profile-card-tooltip-${user.id}`}
        data-tooltip-place="bottom"
      />
    </>
  );
}

export function UserBio({ type, user, date }: UserBioProps) {
  let content: React.ReactNode = null;
  const { t } = useTranslation('common');

  switch (type) {
    case UserType.Author:
      content = (
        <>
          <span className="flex gap-1">
            <span>{t('biography_author.top_label')}</span>
            <ProfileLink user={user} />
          </span>
          <span>
            {`${t('biography_author.date_label')}: ${moment(date).format('MMM DD, YYYY')}`}
          </span>
        </>
      );
      break;
    case UserType.Editor:
      content = (
        <>
          <span className="flex gap-1">
            <span>{t('biography_editor.top_label')}</span>
            <ProfileLink user={user} />
          </span>
          <span>
            {`${t('biography_editor.date_label')}: ${moment(date).format('MMM DD, YYYY')}`}
          </span>
        </>
      );
      break;
    case UserType.Reviewer:
      content = (
        <>
          <span className="flex gap-1">
            <span>{t('biography_review.top_label')}</span>
            <ProfileLink user={user} />
          </span>
          <span>
            {`${t('biography_review.date_label')}: ${moment(date).format('MMM DD, YYYY')}`}
          </span>
        </>
      );
      break;
  }

  return (
    <div className="flex items-center gap-3 px-2 py-4 grow">
      {user.avatarUrl ? (
        <Image
          className="w-8 h-8 rounded-full overflow-hidden"
          src={user.avatarUrl.url}
          alt="user profile image"
          width={300}
          height={300}
        />
      ) : (
        <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
          <UserProfileIcon className="w-6 h-6 text-gray-500" />
        </div>
      )}

      <div className="flex flex-col text-gray-500 text-xs font-medium gap-1">
        {content}
      </div>
      <Tooltip
        id={`profile-card-tooltip-${user.id}`}
        className="bg-gray-50 text-black border rounded-sm shadow-sm z-50 opacity-100"
        clickable
      >
        <ProfileCard user={user} />
      </Tooltip>
    </div>
  );
}
