import { DynamicContentTypes } from 'types/cms';
import {
  ButtonProps,
  Image,
  Link,
  ParagraphHeaderProps,
  ProductButtonProps,
  SeeMoreButtonProps,
} from './common';

export type Alignment = 'left' | 'center' | 'right';
export type VerticalAlignment = 'top' | 'bottom';

export interface CustomerReviewProps {
  id: number;
  customerName?: string;
  customerInfo?: string;
  review?: string;
  rating?: number;
}

export interface FAQProps {
  id: number;
  autoExpand: boolean;
  title: string;
  boxBackground?: string;
  titleColor?: string;
  subtitleColor?: string;
  customEditor?: string;
}

export interface FAQTab {
  id: number;
  title: string;
  faqs: FAQProps[] | null;
}

export interface SingleLogoProps {
  id: number;
  logo: ResponsiveImageProps | null;
  content: string | null;
  contentColor?: string;
  itemsPerRow?: number;
  url?: string;
  pageSlug?: string;
  totalLogos?: number;
  linkRel?: string;
}

export interface DynamicContentPropsInterface {
  id: number;
  componentType: DynamicContentTypes;
}

export interface CustomerReviewsProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.REVIEWS;
  productName: string | null;
  featureImageUrl: string | null;
  brand: string | null;
  productDescription: string | null;
  averageRatingValue: number | null;
  totalReviewCount: number | null;
  header: ParagraphHeaderProps | null;
  reviews: CustomerReviewProps[] | null;
  background?: string;
}

export interface BlogCardProps {
  id: number;
  author: string;
  title: string;
  titleColor: string;
  subtitle: string;
  subtitleColor: string;
  url: string;
  linkRel: string;
  thumbnailUrl: string;
  publishedDate: string;
  authorDateColor?: string;
}
export interface BlogTab {
  id: number;
  title: string;
  blogs: BlogCardProps[];
  additionalBlogs?: BlogCardProps[];
}

export interface BlogTab2 {
  id: number;
  title: string;
  blogs: BlogCardProps[];
}

export interface BlogsProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BLOGS;
  id: number;
  header: ParagraphHeaderProps | null;
  seeMoreButton: SeeMoreButtonProps | null;
  blogs: BlogCardProps[];
  additionalBlogs?: BlogCardProps[];
  background?: string;
}

export interface BlogsTabProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BLOGSTAB;
  id: number;
  header: ParagraphHeaderProps | null;
  seeMoreButton: SeeMoreButtonProps | null;
  blogTabs: BlogTab[];
  background?: string;
}

export interface BlogsTab2Props extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BLOGSTAB2;
  id: number;
  header: ParagraphHeaderProps | null;
  blogTabs: BlogTab2[];
  background?: string;
}

export interface FaqAndSeoProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.FAQSEO;
  bg: string;
  faqs: FAQProps[] | null;
  header: ParagraphHeaderProps | null;
  button?: ButtonProps | null;
}

export interface FAQBannerProps {
  id?: number;
  url?: string | null;
  backgroundColor: string;
  linkRel?: string | null;
  image: {
    artDirection: 'responsive' | 'crop_center' | 'use_mobile_image';
    picturePosition: 'left' | 'right';
    desktopImage: Image;
    mobileImage: Image;
  };
}

export interface FaqAndSeoTabProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.FAQSEOTAB;
  bg: string;
  faqTabs: FAQTab[] | null;
  header: ParagraphHeaderProps | null;
  button?: ButtonProps | null;
  banner?: FAQBannerProps | null;
}

export interface TextBoxDataProps {
  id: number;
  title: string;
  subtitle: string;
  titleColor: string;
  subtitleColor: string;
  align: Alignment;
  icon: Image | null;
  button: ButtonProps | null;
  bulletColor: string;
}

export interface TextBoxRowsProps {
  id: number;
  textBoxes: Array<TextBoxDataProps> | null;
  columns: number;
  rowButton: ButtonProps | null;
  remark?: string | null;
}

export interface TextBoxProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.TEXTBOX;
  bg?: string;
  header: ParagraphHeaderProps | null;
  rows: Array<TextBoxRowsProps> | null;
}

export interface NewsletterProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.NEWSLETTER;
  bg?: string;
}

export interface LogoProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.LOGOS;
  moreThanThisShowButton?: number;
  header?: ParagraphHeaderProps | null;
  logos: SingleLogoProps[];
  seeMoreButton?: SeeMoreButtonProps | null;
  background?: string;
  remark?: string | null;
  itemsPerRow?: number;
  ctaButton?: ButtonProps | null;
}

export interface VideoProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.VIDEO;
  background: string;
  video: EmbeddedVideo;
  header?: ParagraphHeaderProps | null;
}

export interface EmbeddedVideo {
  type: string;
  url: string;
  id: string | number;
  title: string;
  width: number;
  height: number;
  thumbnail: string;
}

export interface ParagraphAndImageRowParagraphProps {
  id: number;
  contentPosition: 'under_the_image' | 'before_the_image' | 'next_to_the_image';
  boxBackground?: string;
  title: string;
  subtitle: string;
  align: Alignment;
  titleColor: string;
  subtitleColor: string;
  imageStyle: 'rectangle' | 'square' | 'circle';
  isImageMobileFullWidth: boolean;
  button: ButtonProps | null;
  image: Image | null;
  imgUrl: string | null;
  imgPageSlug?: string;
  bulletColor?: string;
  priority?: boolean;
}

export interface ParagraphAndImageRowsProps {
  id: number;
  paragraph: Array<ParagraphAndImageRowParagraphProps> | null;
  columns: number;
  rowButton?: ButtonProps | null;
  remark: string | null;
}

export interface ParagraphAndImageProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PARAGRAPHIMAGE;
  background?: string;
  header?: ParagraphHeaderProps | null;
  rows: Array<ParagraphAndImageRowsProps> | null;
  priority?: boolean;
}

export interface LinkBuildingSectionProps {
  id: string;
  subsectionTitle: string;
  contentLink: Array<Link>;
}

export interface LinkBuildingProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.LINKBUILDING;
  section: Array<LinkBuildingSectionProps>;
  header: ParagraphHeaderProps | null;
  background?: string;
}

export interface ProductProps {
  id: string;
  url: string;
  hoverUrl?: string | null;
  alt: string;
  width?: number;
  height?: number;
  title: string;
  subTitle: string | null;
  cardSize: 'normal' | 'big';
  button?: ButtonProps | null;
  linkUrl?: string | null;
  linkRel?: string | null;
  linkSlug?: string | null;
}

export interface ProductOverviewProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PRODUCTOVERVIEW;
  product?: Array<ProductProps> | null;
  header?: ParagraphHeaderProps | null;
  background?: string;
  seeMoreButton?: ButtonProps | null;
  cardSize: 'normal' | 'big';
}

export interface SliderProps {
  id: string;
  titleColor: string;
  title: string;
  subtitleColor: string;
  subtitle: string;
  boxBackground: boolean | null;
  align: Alignment;
  mobileBoxPosition: VerticalAlignment;
  backgroundColor?: string;
  contentAlign?: Alignment;
  button?: ButtonProps | null;
  image?: ResponsiveImageProps | null;
  bulletColor?: string;
  titleAlign?: Alignment;
  priority?: boolean;
}

export interface SliderWithCtaProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.SLIDERWITHCTA;
  slider: Array<SliderProps>;
  priority?: boolean;
}

export interface SlideItemProps {
  id: string;
  url?: string | null;
  linkRel?: string | null;
  pageSlug: string;
  image: ResponsiveImageProps | null;
}
export interface SlideShowProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.SLIDESHOW;
  header?: ParagraphHeaderProps | null;
  background?: string;
  autoSlide: boolean;
  slides: Array<SlideItemProps> | null;
  remark?: string | null;
}

export interface TableProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.TABLE;
  content: string | null;
  header?: ParagraphHeaderProps | null;
  background?: string;
  button?: ButtonProps | null;
}

export interface ImpactTextProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.IMPACTTEXT;
  image: ResponsiveImageProps | null;
  button?: ButtonProps | null;
  content: string | null;
}

export interface MarkdownWithBulletProps {
  bulletColor: string;
  subtitle: string;
  align: React.CSSProperties['textAlign'];
  subtitleColor: string;
  cssClass?: string;
}

export interface ResponsiveImageProps {
  id: string;
  artDirection: 'responsive' | 'crop_center' | 'use_mobile_image';
  desktopImage: Image;
  mobileImage: Image | null;
}

export interface MobileFirstBannerProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.MOBILEFIRSTBANNER;
  backgroundColor: string;
  url?: string | null;
  linkRel?: string | null;
  pageSlug?: string;
  image: ResponsiveImageProps | null;
  priority?: boolean;
}

export interface ProductListingProps {
  id: string;
  titleColor: string;
  title: string;
  subtitleColor: string;
  subtitle: string;
  button?: ButtonProps | null;
  image?: ResponsiveImageProps | null;
  bulletColor?: string;
  content: string | null;
}

export interface ProductListingModuleProps
  extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PRODUCTLISTING;
  header?: ParagraphHeaderProps | null;
  productListing: Array<ProductListingProps> | null;
  seeMoreButton?: ButtonProps | null;
}

export interface CategoryProps {
  id: string;
  url: string;
  hoverUrl?: string | null;
  alt: string;
  width?: number;
  height?: number;
  title: string;
  linkUrl?: string | null;
  linkRel?: string;
  linkSlug?: string;
}

export interface CategoryModuleProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.CATEGORY;
  categories?: Array<CategoryProps> | null;
  header?: ParagraphHeaderProps | null;
  seeMoreButton?: ButtonProps | null;
}

export interface QuestionnaireSliderProps {
  title: string;
  minValue: number;
  maxValue: number;
  interval: number;
  suffix?: string;
}

export interface ChoiceProps {
  code: string;
  displayName: string;
  icon?: Image | null;
}

export interface ChoiceSectionProps {
  title: string;
  subtitle?: string;
  choices: Array<ChoiceProps>;
}

export interface QuestionnaireButtonProps {
  title: string;
  url: string;
  linkRel?: string | null;
}

export interface ShortQuestionnaireProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.SHORTQUESTIONNAIRE;
  title?: string;
  slider: QuestionnaireSliderProps;
  choiceSection: ChoiceSectionProps;
  button: QuestionnaireButtonProps;
}

export interface Bullet {
  id: number;
  bulletIcon: string;
  bulletDetails: string;
}

export interface ProductCategoryItem {
  id?: number;
  title: string;
  subtitle: string;
  content?: string | null;
  artDirection: 'responsive' | 'crop_center' | 'use_mobile_image';
  desktopImage?: Image;
  mobileImage?: Image;
  bullets: Bullet[];
  learnMore?: ProductButtonProps | null;
  applyNow?: ProductButtonProps | null;
}
export interface ProductCategoryTab {
  id: number;
  title: string;
  productCategoryItems: ProductCategoryItem[];
}

export interface ProductListingAndTabsProps
  extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PRODUCTLISTINGANDTABS;
  background?: {
    id: number;
    color: string;
  };
  header: ParagraphHeaderProps | null;
  productCategoryTabs: ProductCategoryTab[];
}

export interface BasicText {
  id: number;
  text: string;
  color: string;
  backgroundColor: string;
}

export interface BasicImage {
  id: number;
  image: Image;
  link: string;
}

export interface BasicLink {
  id: number;
  text: string;
  url: string;
}

export interface ProductCardProps {
  id: number;
  highlight: BasicText;
  image: ResponsiveImageProps;
  productName: BasicText;
  label: BasicText;
  usp_items: Bullet[];
  cta: ProductButtonProps[];
  coverageImages: BasicImage[];
}

export interface ProductCardsListingProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PRODUCTCARDSLISTING;
  header: ParagraphHeaderProps | null;
  products: ProductCardProps[];
}

export interface ProductListingTwoColumnProps
  extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.PRODUCTLISTINGTWOCOLUMNS;
  header?: ParagraphHeaderProps | null;
  productListing: Array<ProductListingProps> | null;
  seeMoreButton?: ButtonProps | null;
}
export interface BannerInPageFormProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BANNERINPAGEFORM;
  background: string;
  header?: ParagraphHeaderProps | null;
  titleColor?: string;
  title?: string;
  qFlowId: string;
  alignment: 'left' | 'right' | 'center';
  desktopImage: Image | null;
  mobileImage?: Image | null;
  button: ButtonProps | null;
}

export interface SalaryProps {
  title: string;
  minValue: string;
  maxValue: string;
  defaultValue?: string;
  suffix?: string;
}

export interface LoanAmountProps {
  title: string;
  minValue?: string;
  defaultValue?: string;
  suffix?: string;
  placeholder?: string | null;
}

export interface LoanTermProps {
  title: string;
  defaultValue?: string;
  defaultUnit: string;
}

export interface BiographyUsersProp {
  id: number;
  isActive: boolean;
  role: string;
  userName: string;
  details: string;
  inputUrl: string;
  bioPic: ResponsiveImageProps;
  position: string;
  issueDate: string;
}

export interface BreadcrumbItemsProp {
  id: number;
  name: string;
  urlPath?: string;
}

export interface MonthlyPaymentProps {
  title: string;
  suffix?: string;
  tooltips?: string;
}
export interface LoanWizardProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.LOANWIZARD;
  title: string;
  interestRate: number;
  sliderSalary: SalaryProps;
  sliderLoanAmount: LoanAmountProps;
  dropDownLoanTerm: LoanTermProps;
  monthlyPayment: MonthlyPaymentProps;
  button: ButtonProps | null;
}
export interface MapAndTextBoxProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.MAPANDTEXTBOX;
  rows: any;
  header: ParagraphHeaderProps;
}

export interface BiographyProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BIOGRAPHY;
  header?: ParagraphHeaderProps | null;
  users: Array<BiographyUsersProp> | null;
}

export enum SocialPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  TikTok = 'TikTok',
  Youtube = 'Youtube',
}

interface Social {
  id: number;
  platform: SocialPlatform;
  url: string;
}

export interface BiographyUserProps {
  id: number;
  firstname: string;
  lastname: string;
  position: string;
  details: string;
  avatarUrl?: Image;
  profileUrl: string;
  social: Social[];
}

export interface BiographyNewProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BIOGRAPHYNEW;
  enabled: boolean;
  author: BiographyUserProps | null;
  publishedDate: Date;
  editor: BiographyUserProps | null;
  lastEditedDate: Date | null;
  reviewer: BiographyUserProps | null;
  reviewedDate: Date | null;
}

export interface BreadcrumbProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.BREADCRUMB;
  background?: string;
  items: Array<BreadcrumbItemsProp> | null;
}

export interface DetailsProps extends DynamicContentPropsInterface {
  icon: Image | null;
  content: string;
}
export interface ListingItemsProps extends DynamicContentPropsInterface {
  columnPerBox: string;
  displayStarRating: boolean;
  image: Image | null;
  imageAlignment: string;
  imageStyle: string;
  rating: number;
  subtitle: string;
  title: string;
  titleColor: string;
  details: Array<DetailsProps>;
  button: ButtonProps | null;
}
export interface YouMayAlsoLikeProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.YOUMAYALSOLIKE;
  background?: string;
  header?: ParagraphHeaderProps | null;
  listingItems: Array<ListingItemsProps> | null;
}

export interface MonthlySalaryProps {
  title: string;
  suffix?: string;
  tooltips?: string | null;
  placeholder?: string | null;
}

export interface InterestRateProps {
  interestRate: number;
  tooltips?: string | null;
}

export interface LoanTermInputProps {
  title: string;
  tooltips?: string | null;
  loanTermYear?: number | null;
  loanTermMonth?: number | null;
}
export interface LoanWizardTextInputProps extends DynamicContentPropsInterface {
  componentType: DynamicContentTypes.LOANWIZARDTEXTINPUT;
  title: string;
  interestRate: InterestRateProps;
  salary: MonthlySalaryProps;
  loanAmount: LoanAmountProps;
  loanTerm: LoanTermInputProps;
  payment: MonthlyPaymentProps;
  button: ButtonProps | null;
}
