import { BiographyUserProps, SocialPlatform } from 'interfaces/cms/content';
import Image from 'next/image';
import Link from 'next/link';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  UserProfileIcon,
  YoutubeIcon,
} from '@alphafounders/icons';

interface ProfileCardProps {
  user: BiographyUserProps;
}

export default function ProfileCard({ user }: ProfileCardProps) {
  return (
    <div className="flex flex-col gap-2 w-[300px]">
      <div className="flex flex-row gap-2">
        {user.avatarUrl ? (
          <Image
            className="w-10 h-10 rounded-full"
            src={user.avatarUrl.url}
            alt="user profile image"
            width={300}
            height={300}
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
            <UserProfileIcon className="w-6 h-6 text-gray-500" />
          </div>
        )}

        <div className="flex flex-col">
          <Link
            href={user.profileUrl || '#'}
            className="font-medium text-sm hover:underline hover:text-blue-500 cursor-pointer"
          >
            {user.firstname} {user.lastname}
          </Link>
          <span className="text-gray-600 text-xs line-clamp-2">
            {user.position}
          </span>
        </div>
      </div>

      <div className="text-gray-500 text-xs line-clamp-3">{user.details}</div>

      <div className="flex flex-wrap flex-row gap-3 py-2">
        {user.social.map((social) => {
          return (
            <Link
              key={user.id.toString() + social.id.toString()}
              href={social.url || '#'}
              target="_blank"
              className="text-blue-500 text-xs cursor-pointer"
            >
              {renderSocialLogo(social.platform)}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function renderSocialLogo(platform: SocialPlatform) {
  return {
    [SocialPlatform.Facebook]: <FacebookIcon className="w-5 h-5" />,
    [SocialPlatform.Instagram]: <InstagramIcon className="w-5 h-5" />,
    [SocialPlatform.LinkedIn]: <LinkedInIcon className="w-5 h-5" />,
    [SocialPlatform.TikTok]: <TikTokIcon className="w-5 h-5" />,
    [SocialPlatform.Youtube]: <YoutubeIcon className="w-5 h-5" />,
  }[platform];
}
