import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Markdown from 'components/common/Markdown';
import ArrowLeft from '@public/icons/left-navigation.svg';
import ArrowRight from '@public/icons/right-navigation.svg';
import styles from './Tab.module.scss';

function Tab(props: any) {
  const [active, setActive] = useState(props.default);
  const [showNavigation, setShowNavigation] = useState(false);
  const scrollRef = useRef(null);

  function handleChange(index: number) {
    setActive(index);
  }

  const computeNavigation = () => {
    if (scrollRef.current === null) return;
    const { children, offsetWidth } = scrollRef.current as {
      children: any;
      offsetWidth: number;
    };
    let contentWidth = 0;
    for (const child of children) {
      contentWidth += Number(child.offsetWidth);
    }
    contentWidth += (children.length - 1) * 4;
    setShowNavigation(contentWidth > offsetWidth);
  };

  useEffect(() => {
    setActive(props.default);
    computeNavigation();
  }, [props.default]);

  const prev = () => {
    if (scrollRef.current === null) return;
    const currentScroll = scrollRef.current as HTMLUListElement;
    requestAnimationFrame(() => {
      const itemWidth = parseInt(
        getComputedStyle(currentScroll.children[0]).width
      );
      currentScroll.scrollLeft -= itemWidth;
    });
    handleChange(active - 1);
  };

  const next = () => {
    if (scrollRef.current === null) return;
    const currentScroll = scrollRef.current as HTMLUListElement;
    requestAnimationFrame(() => {
      const itemWidth = parseInt(
        getComputedStyle(currentScroll.children[0]).width
      );
      currentScroll.scrollLeft += itemWidth;
    });
    handleChange(active + 1);
  };

  return (
    <>
      {props.children.length > 1 && (
        <div className="w-full mx-auto mb-[1.875rem] flex justify-center items-center">
          <div className="mr-[0.719rem]">
            <ArrowLeft
              className={clsx(
                'fill-primaryColor cursor-pointer',
                showNavigation && active !== 0 ? 'block' : 'hidden'
              )}
              onClick={prev}
            />
          </div>
          <ul ref={scrollRef} className={styles.content}>
            {props.children.map((elem: any, index: number) => {
              return (
                <li
                  key={index}
                  style={{
                    WebkitTapHighlightColor: 'transparent',
                  }}
                  className={clsx(
                    'w-auto md:w-auto whitespace-nowrap justify-self-center text-center rounded-full cursor-pointer py-2 px-4 border border-solid border-borderColor',
                    index === active
                      ? 'bg-primaryColor text-white'
                      : 'bg-white text-primaryColor hover:bg-grayColor'
                  )}
                  onClick={() => handleChange(index)}
                >
                  <Markdown textAlign="center" md={elem.props.title} />
                </li>
              );
            })}
          </ul>
          <div className="ml-[0.719rem]">
            <ArrowRight
              className={clsx(
                'fill-primaryColor cursor-pointer',
                showNavigation && active !== props.children.length - 1
                  ? 'block'
                  : 'hidden'
              )}
              onClick={next}
            />
          </div>
        </div>
      )}
      <div>{props.children[active]}</div>
    </>
  );
}

export default Tab;
