import { useRouter } from 'next/router';

export function Preview({ previewMode }: { readonly previewMode: boolean }) {
  const router = useRouter();
  const exitPreviewMode = async () => {
    const response = await fetch('/api/exit-preview');
    if (response) {
      router.reload();
    }
  };

  if (!previewMode) return null;
  return (
    <div className="my-3 text-sm text-[#6c757d]">
      <span>You are currently viewing in Preview Mode. </span>
      <a
        role="button"
        className="text-primaryColor underline hover:text-buttonPrimaryBgHover"
        onClick={() => exitPreviewMode()}
      >
        Turn Off Preview Mode
      </a>
    </div>
  );
}
