import React, { useRef } from 'react';
import { BlogTab2 } from 'interfaces/cms/content';
import BlogCard2 from '../Blogs/BlogCard2';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ArrowLeftIcon } from '@alphafounders/icons';

export default function BlogContainer({ blogTab }: { blogTab: BlogTab2 }) {
  const swiperRef = useRef<SwiperClass>();

  return (
    <div className="relative px-16">
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        modules={[Pagination, Grid]}
        pagination={{
          el: '.swiper-pagination-custom',
          clickable: true,
        }}
        grid={{ rows: 2, fill: 'row' }}
        slidesPerGroup={1}
        rewind={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        breakpoints={{
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            grid: { rows: 2 },
          },
        }}
      >
        {blogTab.blogs.map((blog) => (
          <SwiperSlide key={blog.id}>
            <BlogCard2 {...blog} key={blog.id} />
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        className="absolute z-50 bg-primary p-3 rounded-full top-1/2 -left-0 transform -translate-y-1/2"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ArrowLeftIcon fill="white" className="w-4 h-4" />
      </button>

      <button
        className="absolute z-50 bg-primary p-3 rounded-full top-1/2 -right-0 transform -translate-y-1/2"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ArrowLeftIcon fill="white" className="w-4 h-4 rotate-180" />
      </button>

      <div className="swiper-pagination-custom mt-2 flex flex-row justify-center"></div>
    </div>
  );
}
