import useTranslation from 'next-translate/useTranslation';

import { BlogCardProps } from 'interfaces/cms/content';
import Link from 'next/link';
import Image from 'next/image';

function BlogCard2({
  author,
  publishedDate,
  authorDateColor,
  url,
  linkRel,
  title,
  titleColor,
  subtitle,
  subtitleColor,
  thumbnailUrl,
}: BlogCardProps) {
  const { t } = useTranslation('common');

  return (
    <div className="max-w-lg flex flex-row w-full gap-4 cursor-default">
      <Link href={url} rel={linkRel}>
        <Image
          src={thumbnailUrl}
          alt={title}
          width={120}
          height={40}
          unoptimized
          className="rounded-lg w-36 object-cover"
        />
      </Link>

      <div className="w-full flex flex-col gap-1">
        <Link href={url} rel={linkRel} className="hover:underline">
          <div
            className="font-bold text-base line-clamp-2"
            style={{ color: titleColor }}
          >
            {title}
          </div>
        </Link>

        <span style={{ color: subtitleColor }} className="text-sm line-clamp-1">
          {subtitle}
        </span>

        <div
          style={{ color: authorDateColor }}
          className="text-xs flex gap-2 items-center"
        >
          <span>
            {t('by')} {author}
          </span>
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {publishedDate}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard2;
