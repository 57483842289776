import { BiographyNewProps } from 'interfaces/cms/content';
import { UserBio, UserType } from './UserBio';
import AngleDown from '@public/icons/angle-down.svg';

export default function BiographyNew(props: BiographyNewProps) {
  if (!props.enabled || !props.author) return null;

  let userCountLabel = 0;
  if (props.editor && props.lastEditedDate) userCountLabel++;
  if (props.reviewer && props.reviewedDate) userCountLabel++;

  return (
    <div className="max-w-[69.375rem] mx-auto flex flex-wrap border-b px-3">
      <div className="flex flex-row items-center gap-2 peer grow-0 md:grow">
        <UserBio
          type={UserType.Author}
          user={props.author}
          date={props.publishedDate}
        />

        <label htmlFor="toggle" className="md:hidden flex items-center gap-2">
          <input id="toggle" type="checkbox" className="border peer sr-only" />
          <span className="text-gray-700 font-medium text-sm">
            +{userCountLabel} more
          </span>
          <AngleDown className="peer-checked:rotate-180" />
        </label>
      </div>
      <div className="flex grow-[2] flex-wrap min-w-fit invisible h-0 peer-has-[:checked]:visible peer-has-[:checked]:h-auto md:visible md:h-auto">
        {props.editor && props.lastEditedDate && (
          <UserBio
            type={UserType.Editor}
            user={props.editor}
            date={props.lastEditedDate}
          />
        )}
        {props.reviewer && props.reviewedDate && (
          <UserBio
            type={UserType.Reviewer}
            user={props.reviewer}
            date={props.reviewedDate}
          />
        )}
      </div>
    </div>
  );
}
