import {
  CarGarageIcon,
  MoneyCashBagIcon,
  PaymentIcon,
  PriceTagIcon,
  RCHeartBlankIcon,
  RCHeartButterFlyPeaIcon,
  RCHeartDandelionIcon,
  RCHeartEmeraldIcon,
  RCHeartGrayIcon,
  RCHeartLavenderIcon,
  RCHeartPinkIcon,
  RCHeartSecondaryIcon,
  RCHeartTurquoiseIcon,
  ShieldCheckIcon,
  WalletCreditCardIcon,
  WrenchIcon,
  RCHeartRedIcon,
  RCHeartGreenIcon,
  RCHeartGoldIcon,
} from '@alphafounders/icons';
import { Bullet } from 'interfaces/cms/content';
import Markdown from 'react-markdown';

export default function BulletList({ items }: { items: Bullet[] }) {
  return (
    <ul className="flex flex-col gap-2">
      {items?.map(({ id, bulletIcon, bulletDetails }) => (
        <li key={id} className="flex flex-row gap-2">
          <BulletIconComponent
            bulletIcon={bulletIcon}
            className="block w-5 h-5 shrink-0"
          />
          <Markdown
            className="text-sm inline"
            components={{
              p: ({ ...props }) => <div className="line-clamp-2" {...props} />,
            }}
          >
            {bulletDetails}
          </Markdown>
        </li>
      ))}
    </ul>
  );
}

interface BulletIconComponentProps {
  bulletIcon: string;
  [key: string]: any;
}

function BulletIconComponent({
  bulletIcon,
  ...rest
}: BulletIconComponentProps) {
  const Icon = {
    price: PriceTagIcon,
    installment: PaymentIcon,
    garage: CarGarageIcon,
    insurance: ShieldCheckIcon,
    wrench: WrenchIcon,
    cashbag: MoneyCashBagIcon,
    walletcard: WalletCreditCardIcon,
    heart_turquoise: RCHeartTurquoiseIcon,
    heart_grey: RCHeartGrayIcon,
    heart_pink: RCHeartPinkIcon,
    heart_emerald: RCHeartEmeraldIcon,
    heart_blank: RCHeartBlankIcon,
    heart_secondary: RCHeartSecondaryIcon,
    heart_butterflypea: RCHeartButterFlyPeaIcon,
    heart_lavender: RCHeartLavenderIcon,
    heart_dandelion: RCHeartDandelionIcon,
    heart_green: RCHeartGreenIcon,
    heart_red: RCHeartRedIcon,
    heart_gold: RCHeartGoldIcon,
  }[bulletIcon];

  if (!Icon) return null;

  return <Icon {...rest} />;
}
