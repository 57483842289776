import { useRef } from 'react';
import { ProductCardsListingProps } from 'interfaces/cms/content';
import SwiperInstance from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { ArrowLeftIcon } from '@alphafounders/icons';
import { Pagination } from 'swiper/modules';
import ProductCard from './ProductCard';
import ParagraphHeader from 'components/ParagraphHeader';

export default function ProductCardsListing({
  header,
  products,
}: ProductCardsListingProps) {
  const swiperRef = useRef<SwiperInstance>();

  const maxCoverageImages = Math.max(
    ...products.map((product) => product.coverageImages.length)
  );

  return (
    <div className="py-8">
      {header && (
        <>
          <ParagraphHeader {...header} />
          <div className="mb-[1.875rem]" />
        </>
      )}
      <div className="max-w-6xl mx-auto px-10 mb-10">
        <div className="relative">
          <Swiper
            modules={[Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            pagination={{
              el: '.swiper-pagination-custom',
              clickable: true,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
            rewind={true}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            className="p-2"
          >
            {products.map((product) => (
              <SwiperSlide key={product.id} className="h-auto">
                {
                  <ProductCard
                    product={product}
                    maxCoverageImages={maxCoverageImages}
                  />
                }
              </SwiperSlide>
            ))}
          </Swiper>

          <SlideButton
            direction="left"
            onClick={() => swiperRef.current?.slidePrev()}
          />

          <SlideButton
            direction="right"
            onClick={() => swiperRef.current?.slideNext()}
          />

          <div className="swiper-pagination-custom mt-2 flex flex-row justify-center"></div>
        </div>
      </div>
    </div>
  );
}

function SlideButton({
  direction,
  onClick,
}: {
  direction: 'left' | 'right';
  onClick: () => void;
}) {
  return (
    <button
      title={direction === 'left' ? 'Previous' : 'Next'}
      className={`bg-primaryColor text-white px-2 py-3 rounded absolute top-1/2 -translate-y-1/2 ${direction === 'left' ? '-left-1' : '-right-1'} z-10`}
      onClick={onClick}
    >
      <ArrowLeftIcon
        width="10px"
        fill="white"
        className={direction == 'right' ? 'rotate-180' : ''}
      />
    </button>
  );
}
