import ParagraphHeader from 'components/ParagraphHeader';
import { BlogsTab2Props } from 'interfaces/cms/content';
import Tab from 'components/common/Tab';
import Panel from 'components/common/Tab/Panel';
import BlogContainer from './BlogContainer';

function BlogsTab2({
  header,
  blogTabs,
  background: backgroundColor,
}: BlogsTab2Props) {
  return (
    <div style={{ backgroundColor }} className="py-8">
      <div className="max-w-[69.375rem] mx-auto px-5">
        {header && (
          <>
            <ParagraphHeader {...header} />
            <div className="mb-8" />
          </>
        )}
        <Tab default={0}>
          {blogTabs.map((blogTab) => (
            <Panel key={`Tab-${blogTab.id}`} title={blogTab.title}>
              <BlogContainer blogTab={blogTab} />
            </Panel>
          ))}
        </Tab>
      </div>
    </div>
  );
}

export default BlogsTab2;
